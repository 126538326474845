<template>
  <div class="contact-container">
    <h1 id="contact">KONTAKT</h1>
    <div class="title-separator-container">
      <div class="title-separator"/>
    </div>
    <v-row class="justify-center">
      <v-col cols="12" class="text-center">
        <v-card flat class="contact-info-container">
          <v-card-text>
            Die Wohnungen werden ohne Makler, vom Eigentümer der Liegenschaft angeboten. <br/> <br/>
            E-Mail: <a Href="mailto:amnussbaum.wohnen@gmail.com">amnussbaum.wohnen@gmail.com</a><br/>
            Tel.: <a href="tel:+43-676-88270241">+43-676-88270241</a>, Bernd Speckbacher
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="10" lg="8" xl="7">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card flat>
            <v-card-text class="text-left">
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field v-model="form.name" :rules="rules" label="Name" required outlined/>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field v-model="form.email" :rules="eMailRules" label="E-Mail Adresse" required outlined/>
                </v-col>
                <v-col cols="12" sm="12" md="6" style="display: none;">
                  <v-text-field v-model="form.honey" outlined/>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                      :rules="rules"
                      required outlined
                      v-model="form.text"
                      label="Nachricht"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider/>
            <v-card-actions>
              <v-btn outlined color="success" x-large @click="submit()" :loading="loading" :disabled="loading">
                Anfrage senden
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar.open" :timeout="snackbar.timeout" :color="snackbar.type">
      {{ snackbar.text }}
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "Contact",
  data: () => ({
    snackbar: {
      open: false,
      timeout: 6000,
      type: 'success',
      text: null
    },
    valid: true,
    loading: false,
    form: {
      name: null,
      email: null,
      text: null,
      honey: null,
    },
    rules: [
      v => !!v || 'Pflichtfeld'
    ],
    eMailRules: [
      v => !!v || 'Pflichtfeld',
      v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test(v) || 'E-Mail Adresse ist ungültig'
    ]
  }),
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.$http.post('https://formsubmit.co/ajax/amnussbaum.wohnen@gmail.com', {
          Name: this.form.name,
          Mail: this.form.email,
          Message: this.form.text,
          _honey: this.form.honey,
        }).then(
            (data) => {
              this.loading = false;
              this.$refs.form.reset();
              this.snackbar.text = 'Die Anfrage wurde erfolgrech versendet!';
              this.snackbar.type = 'success';
              this.snackbar.open = true;
              console.log(data);
            },
            (data) => {
              this.loading = false;
              this.snackbar.text = 'Die Anfrage konnte nicht versendet werden!';
              this.snackbar.type = 'error';
              this.snackbar.open = true;
              console.error(data);
            },
        );
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/variables.scss";
@import "../../assets/scss/layout.scss";
.contact-container {
  padding-top: 60px;
  padding-bottom: 60px;
  margin-left: 185px;
  margin-right: 185px;
  text-align: center;
  @include MQ(L) {
    margin-left: 135px;
    margin-right: 135px;
  }
  @include MQ(M) {
    margin-left: 60px;
    margin-right: 60px;
  }
  @include MQ(S) {
    margin-left: 25px;
    margin-right: 25px;
  }
  @include MQ(XS) {
    padding-top: 30px;
    margin-left: 10px;
    margin-right: 10px;
  }
  h1 {
    font-size: 3em;
    @include MQ(XS) {
      font-size: 2em;
    }
  }
  .title-separator-container {
    display: flex;
    justify-content: center;
    .title-separator {
      width: 150px;
      height: 8px;
      border-top: 1px solid rgba(0, 0, 0, 0.2);
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }
  }
  .v-card {
    margin-top: 40px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    .v-card__text {
      font-size: 1.3em;
      @include MQ(XS) {
        font-size: 1em;
      }
      a {
        text-decoration: none;
      }
    }
    .v-card__actions {
      .v-btn {
        @include MQ(XS) {
          width: 100%;
        }
      }
    }
  }
  .contact-info-container {
    display: inline-block;
  }
}
</style>
